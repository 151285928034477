import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { Modal } from "react-bootstrap";
import { changePointer } from "../../util/estimationUtils";
import { carRotatorConfig } from "../../util/constants";
import { carImageValidations } from "../estimationRequest/validations";
import { FileInput } from "./";
import { isFile } from "../../util/common";

const ImagesUploadModal = ({
  show,
  name,
  savedValues,
  setUploadedImages,
  carApi,
  close,
  openCameraOnMobile
}) => {
  const { t } = useTranslation();

  const key = name.replace("Image", "");

  const handleSubmit = (values) => {
    if (!isFile(values.CarImage)) return close();

    const updated = { ...savedValues };
    updated[key] = values.CarImage;
    setUploadedImages(updated);
    close();
    changePointer(key);

    if (Object.keys(updated).length < 4) {
      carRotatorConfig[key].every((side) => {
        if (!updated[side]) {
          carApi.images.playToLabel(side, { speed: 2 });
          return false;
        }
        return true;
      });
    }
  };

  return (
    <Modal className="car-images-modal" show={show} onHide={close} centered size="lg">
      <Modal.Body>
        <Formik
          initialValues={{ CarImage: savedValues?.[key] }}
          validationSchema={carImageValidations}
          onSubmit={handleSubmit}>
          {(formProps) => (
            <Form>
              <h4>
                {t("estimationRequest.uploadText1")} <span>{t("estimationRequest." + name)}</span>{" "}
                {t("estimationRequest.uploadText2")}
              </h4>

              <FileInput
                name="CarImage"
                formProps={formProps}
                icon
                loading
                openCameraOnMobile={openCameraOnMobile}
              />

              <div className="d-flex justify-content-center mt-2">
                <button type="button" className="back-btn" onClick={close}>
                  {t("estimationRequest.back")}
                </button>
                <input type="submit" className="mx-0" value={t("estimationRequest.saveBtn")} />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ImagesUploadModal;
