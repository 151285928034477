import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DayPicker } from "react-day-picker";
import { UserService } from "../../services";
import { Field } from "formik";
import { currentLanguage } from "../../util/common";
import { ar } from "date-fns/locale";
import { getHolidayDates } from "../../util/dateAndTimeUtils";
import "react-day-picker/dist/style.css";

const BranchSlotPicker = ({
  parentClassName = "",
  branchSelector,
  selectedDay,
  setSelectedDay,
  formikProps
}) => {
  const { key: selectorKey, value: selectorValue } = branchSelector;
  const { setFieldValue, errors, touched } = formikProps;

  const calenderRef = useRef();
  const { t } = useTranslation();

  const lang = currentLanguage();
  const timeSlotsHeight = calenderRef.current?.offsetHeight - 54;

  const [closedDays, setClosedDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    if (!selectorValue) return;

    getBranchSlots();

    return () => {
      setClosedDays([]);
      setTimeSlots([]);
    };
  }, [selectorValue]);

  const getBranchSlots = async () => {
    try {
      const branch = await UserService.getSlotsForCarPickup({
        [selectorKey]: selectorValue
      });
      setTimeSlots(branch.fastCollectSlots);
      eliminateClosedDays(branch.shopTimings);
      const holidayDates = getHolidayDates(branch.ocassions);
      setHolidays(holidayDates.map((d) => new Date(d)));
    } catch (err) {
      console.error(err);
    }
  };

  const eliminateClosedDays = (timings) => {
    const forCalender = [];
    const shopClosings = timings.map(({ isClosed }) => isClosed);

    const displaceSunday = shopClosings.pop();
    shopClosings.unshift(displaceSunday);

    shopClosings.forEach((closed, index) => {
      if (closed) forCalender.push(index);
    });

    setClosedDays(forCalender);
  };

  const handleDayClick = async (day, { selected }) => setSelectedDay(selected ? undefined : day);

  const renderDay = ({ date: day }) => <div className="day true">{day.getDate()}</div>;

  return (
    <div className={parentClassName}>
      <div className="date-time-select mb-1">
        <div className="slots-picker w-100" ref={calenderRef}>
          <DayPicker
            name="AppointmentDate"
            id="AppointmentDate"
            onDayClick={handleDayClick}
            defaultMonth={selectedDay}
            selected={selectedDay}
            components={{
              DayContent: renderDay
            }}
            locale={lang === "ar" ? ar : null}
            disabled={[...holidays, { dayOfWeek: closedDays }, { before: new Date() }, new Date()]}
          />
        </div>
        <div className="time-slots-list">
          <div className="select-size">
            <div className="header">
              <p>{t("scheduleAppointment.timeSlots")}</p>
            </div>
            <div
              className="slots"
              style={{
                maxHeight: timeSlotsHeight,
                minHeight: timeSlotsHeight
              }}>
              {selectedDay &&
                timeSlots?.map((time, index) => {
                  return (
                    <div key={index}>
                      <Field
                        id={time}
                        type="radio"
                        value={time}
                        name="scheduleTime"
                        onChange={(event) => setFieldValue("scheduleTime", event?.target.value)}
                      />
                      <label className="active" htmlFor={time}>
                        {time.toUpperCase()}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {errors.scheduleTime && touched.scheduleTime && (
        <span className="red-color">{t(errors.scheduleTime)}</span>
      )}
    </div>
  );
};

export default React.memo(BranchSlotPicker);
