import AddonsService from "../services/addonsService";
import { Trans } from "react-i18next";
import HyperpayService from "../services/hyperpayService";
import {
  currentLanguage as lang,
  downloadPdf,
  loadStorage,
  deleteStorage,
  getQueryData,
  dispatchStorage,
  getLocationPathname
} from "./common";
import { UserService } from "../services";
import { NotificationHelper, Icon } from "../components/shared";

export const calculateTotal = (data, key) => {
  return data.reduce((accumulator, item) => {
    if (Object.prototype.hasOwnProperty.call(item, "quantity")) {
      return accumulator + item[key] * item["quantity"];
    } else {
      return accumulator + item[key];
    }
  }, 0);
};

export const langConfig = {
  skipIcon: {
    en: <Icon iconName="chevronRight" />,
    ar: <Icon iconName="chevronLeft" />
  },
  backIcon: {
    en: <Icon iconName="arrowLeft" />,
    ar: <Icon iconName="arrowRight" />
  },
  breadCrumbIcon: {
    en: <Icon iconName="chevronRight" />,
    ar: <Icon iconName="chevronLeft" />
  },
  drawerPlace: {
    en: "end",
    ar: "start"
  }
};

export const getOffersAndVariantsPayload = (data) =>
  data.map((item) => ({
    _id: item._id,
    price: item.price,
    quantity: item.quantity || 1,
    name: item.name
  }));

export const classes = {
  parent: {
    appointment: "checkout design-props px-lg-5",
    upsell: "checkout track-addons",
    eshop: "checkout e-shop",
    subscription: "checkout subscription-checkout"
  },
  container: {
    appointment: "container-fluid p-0",
    upsell: "container-fluid p-0",
    eshop: "container-fluid",
    subscription: "container p-0"
  },
  childContainer: {
    appointment: { 0: "col-md-12 col-lg-7", 1: "col-md-12 col-lg-5" },
    upsell: { 0: "col-md-12 col-lg-7", 1: "col-md-12 col-lg-5" },
    eshop: { 0: "col-md-12 col-lg-7", 1: "col-md-12 col-lg-5" },
    subscription: { 0: "col-md-12 col-lg-6", 1: "col-md-12 col-lg-6" }
  }
};

export const getAppointmentPayload = (draftVals) => {
  const payload = {
    ...draftVals?.appointment
  };

  const paidOffer = draftVals?.cart?.paidOffer;
  if (paidOffer) payload.isPaidService = !!paidOffer;

  const isAddonSelected = draftVals?.cart?.offers?.length || draftVals?.cart?.products?.length;
  if (isAddonSelected) payload.isAddonSelected = true;

  return payload;
};

export const getOrderPayload = ({ module, draftVals, references }) => {
  let orderPayload = {
    orderType: module,
    variants: draftVals?.variants,
    paymentBrand: draftVals?.cardType?.toLowerCase(),
    promoCode: draftVals?.coupon,
    branchId: location.state?.branchId || "63c10f6aa920ea32701f0b7a",
    language: lang(),
    currentPageUrl: location.pathname,
    appointmentVoucherId: ""
    // ...draftVals.userVat
  };

  if (module === "appointment" || module === "upsell") {
    orderPayload.offers = getOffersAndVariantsPayload(draftVals?.cart.offers);
    orderPayload.variants = getOffersAndVariantsPayload(draftVals?.cart.products);
  }

  if (module === "appointment") {
    const paidOffer = draftVals?.cart?.paidOffer;
    if (paidOffer) {
      if (draftVals?.appointment?.cad) {
        paidOffer.price = paidOffer.price + draftVals?.cadPrice;
      }
      orderPayload.offers.push({
        _id: paidOffer._id,
        price: paidOffer.price,
        quantity: 1,
        name: paidOffer.name
      });
    }

    const isAddonSelected = draftVals?.cart?.offers?.length || draftVals?.cart?.products?.length;
    orderPayload = {
      ...orderPayload,
      appointmentId: references._id,
      branchId: references.branchId,
      orderType: isAddonSelected ? "addon" : "offer"
    };
  } else if (module === "upsell") {
    orderPayload = {
      ...orderPayload,
      ...draftVals?.appointment
    };
  } else if (module === "subscription") {
    orderPayload = {
      ...orderPayload,
      variants: [],
      carNumberPlate: draftVals?.plateNumber + "-" + draftVals?.plateChar,
      branchId: draftVals?.branchId,
      subscriptionId: draftVals?.cart?.offers?.[0]?._id,
      subscribedSubscriptionId: references
    };
  }

  return orderPayload;
};

export const createOrder = async ({
  hyperpayId,
  orderPayload,
  handleFailedPayment,
  appointmentId
}) => {
  try {
    let order = await AddonsService.createAddonOrder(orderPayload);
    order = order.response;
    if (order) {
      if (order.tamaraCheckoutUrl) {
        dispatchStorage(
          "tamaraDetails",
          { tamaraOrderId: order._doc._id, sourceURL: getLocationPathname() },
          "sessionStorage"
        );
        window.location.href = order.tamaraCheckoutUrl;
        return;
      }

      const status = await checkPaymentStatus({
        hyperpayId,
        orderId: order._id,
        appointmentId,
        subscribedSubscriptionId: orderPayload.subscribedSubscriptionId
      });
      if (!status) handleFailedPayment();

      return status;
    }
  } catch (e) {
    handleFailedPayment();
    NotificationHelper.error(<Trans i18nKey="shop.orderErrorNotification" />);
  }
};

export const checkPaymentStatus = async (payload) => {
  try {
    const result = await HyperpayService.verifyPayment(payload);
    if (result?.message === "transaction.success") {
      NotificationHelper.success(<Trans i18nKey="shop.transactionSuccessNotification" />);
      NotificationHelper.success(<Trans i18nKey="shop.orderSuccessNotification" />);
      return true;
    }
  } catch (e) {
    NotificationHelper.error(<Trans i18nKey="shop.transactionErrorNotification" />);
    return false;
  }
};

export const checkTamaraPaymentStatus = async (orderId) => {
  try {
    let result = await HyperpayService.verifyTamaraPayment(orderId);
    result = result.response;
    if (result?.message === "order.tamaraPaymentSuccessful") {
      NotificationHelper.success(<Trans i18nKey="shop.transactionSuccessNotification" />);
      NotificationHelper.success(<Trans i18nKey="shop.orderSuccessNotification" />);
      return result.appointmentId || true;
    }
  } catch (e) {
    NotificationHelper.error(<Trans i18nKey="shop.transactionErrorNotification" />);
    return false;
  }
};

export const generateInvoice = async ({ id, deposit, type, refund, check }) => {
  if (!check) return NotificationHelper.error(<Trans i18nKey="myAccount.noInvoice" />);
  try {
    let pdf;
    if (refund) {
      pdf = await UserService.refundOrderInvoice(id, type);
    } else {
      if (deposit) pdf = await UserService.downloadDepositInvoice(id, type);
      else pdf = await UserService.downloadFinalInvoice(id, type);
    }
    await downloadPdf(pdf, deposit, refund);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const calculateDiscount = (oldPrice, newPrice) => oldPrice - newPrice;

export const vanishDraftValues = () => {
  const hyperpayId = getQueryData("id");
  const draftVals = loadStorage("draftVals", "sessionStorage");
  const tamaraDetails = loadStorage("tamaraDetails", "sessionStorage");
  if (
    (!hyperpayId && !tamaraDetails && draftVals) ||
    (!hyperpayId && tamaraDetails?.sourceURL !== getLocationPathname())
  ) {
    deleteStorage("draftVals", "sessionStorage");
    deleteStorage("tamaraDetails", "sessionStorage");
  }
};

export const doExist = ({ item, type, cart }) => {
  const index = cart[type].findIndex(({ _id }) => item._id === _id);
  return { exist: index >= 0, index: index };
};

export const handleAddDeleteItem = ({ item, type, cart, setCart, t }) => {
  const collection = { ...cart };
  const target = collection[type];
  item = { ...item };
  item.type = type;

  const currentIndex = doExist({ item, type, cart });
  if (currentIndex.exist) {
    target.splice(currentIndex.index, 1);
    NotificationHelper.success(item.name["value_" + lang()] + t("addons.removedItemMsg"), {
      theme: "dark"
    });
  } else {
    target.push(item);
    NotificationHelper.success(item.name["value_" + lang()] + t("addons.addItemMsg"), {
      theme: "dark"
    });
  }

  setCart(collection);
};

export const editAddonsCart = ({ type, item = {}, cart, setCart, t }) => {
  const quantity = item.quantity;
  const addedQuantity = quantity + 1;

  if (!quantity) item.quantity = 1;

  if (type === "+" && addedQuantity >= item.totalRemainingQuantity) {
    NotificationHelper.error(item.name?.value_en + t("addons.quantityExceeded"), {
      theme: "colored"
    });
  }

  if (type === "+" && addedQuantity > item.totalRemainingQuantity) return;

  const cartCopy = { ...cart };
  const itemIndex = cartCopy.products?.findIndex((product) => product._id === item._id);
  if (itemIndex !== -1)
    cartCopy.products[itemIndex].quantity = type === "+" ? addedQuantity : quantity - 1;
  setCart(cartCopy);
};

export const checkTamaraOrder = () => {
  const { tamaraOrderId, sourceURL } = loadStorage("tamaraDetails", "sessionStorage") || {};
  const paymentStatus = getQueryData("paymentStatus");
  deleteStorage("tamaraDetails", "sessionStorage");
  if (tamaraOrderId && !paymentStatus)
    window.location.href = sourceURL + `?id=${tamaraOrderId}&paymentStatus=canceled`;
};

export const getOrderItemKey = (order) => {
  let isOffer = false;
  let isProduct = false;
  if (order?.variantItems?.length && order?.offerItems?.length) {
    isOffer = true;
    isProduct = true;
  } else if (order?.variantItems?.length) isProduct = true;
  else if (order?.offerItems?.length) isOffer = true;

  return {
    isOffer,
    isProduct
  };
};
