import React, { useState, useEffect } from "react";
import { Form, Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import * as Yup from "yup";
import {
  forFocus,
  forKey,
  formatOptionLabel,
  getCarBrandOptions,
  getLocalizedOptions
} from "../../util/selectOptions";
import {
  currentLanguage,
  isOtherCar,
  handleCarPlateNum,
  isBefore2017,
  handleCarPlateChar
} from "../../util/common";
import { UserService } from "../../services";
import { useSelector } from "react-redux";
import { splitCarNumberPlate } from "../../util/carOptionsUtils";
// import estimationRequest from "../../services/estimationRequest";
// import EstimationExistModal from "./modals/EstimationExistModal";

const VehicleDetailsStep = (props) => {
  const {
    setView,
    setShowLoader,
    globalValues,
    setGlobalValues,
    isUpdating,
    serviceId,
    isSubscription
  } = props;
  const { Brand, Model, OtherBrand, OtherModel, Year, plateNumber, plateChar, carNickName } =
    globalValues;

  const { t } = useTranslation();
  const language = currentLanguage();
  const userData = useSelector((state) => state.user.details);

  const [newCar, setNewCar] = useState(null);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [yearsOptions, setYearOptions] = useState([]);
  const [userCars, setUserCars] = useState([]);
  const [userCarIndex, setUserCarIndex] = useState(0);
  const [isSavedCar, setIsSavedCar] = useState(true);
  // const [estimationExistModalOpen, setEstimationExistModalOpen] = useState(false);

  const carNumberPlate = splitCarNumberPlate(userCars?.[userCarIndex]?.carNumberPlate);

  useEffect(() => {
    setupPage();
  }, []);

  const setupPage = async () => {
    if (userData?.id) {
      setShowLoader(true);

      const brands = await getCarBrands();
      if (Brand) await showSelectedData(brands);

      await getUserCars();

      setShowLoader(false);
    }
  };

  const showSelectedData = async (brands) => {
    setIsSavedCar(globalValues.isSavedCar);

    const selectedBrand = brands?.find(
      ({ value_en, value_ar }) => value_en === Brand || value_ar === Brand
    );

    const models = await getCarModels(selectedBrand._id);
    const selectedModel = models?.find(
      ({ value_en, value_ar }) => value_en === Model || value_ar === Model
    );

    if (selectedBrand && selectedModel)
      setNewCar({
        brand: { en: selectedBrand.value_en, ar: selectedBrand.value_ar },
        model: { en: selectedModel.value_en, ar: selectedModel.value_ar }
      });

    await getCarYears(Brand, Model);
  };

  const carBrands = getCarBrandOptions(brandOptions, userCars, t);
  const getCarBrands = async () => {
    try {
      const brands = await UserService.getBrands({ serviceId, isSubscription });
      if (brands) {
        setBrandOptions(brands);
        return brands;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getCarModels = async (brand) => {
    try {
      const models = await UserService.getModels({ brand, serviceId, isSubscription });
      if (models) {
        setModelOptions(models);
        return models;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getCarYears = async (brand, model) => {
    try {
      const years = await UserService.getYears({ brand, model, serviceId, isSubscription });
      if (years) setYearOptions(years);
    } catch (e) {
      console.error(e);
    }
  };

  const getUserCars = async () => {
    try {
      const cars = await UserService.getRecentCars({
        userId: userData?.id,
        serviceId,
        isSubscription
      });
      if (cars) setUserCars(cars.recentCars || []);
    } catch (e) {
      console.error(e);
    }
  };

  const handleBrandChange = async (e, setFieldValue) => {
    setNewCar({ brand: e.localized });
    setModelOptions([]);
    setYearOptions([]);

    if (!e.localized) {
      setUserCarIndex(null);
      setTimeout(() => {
        setUserCarIndex(e.index);
        setIsSavedCar(true);
      }, 0);
    } else {
      setIsSavedCar(false);
      setFieldValue("Model", "");
      setFieldValue("Year", "");
      setFieldValue("plateNumber", "");
      setFieldValue("plateChar", "");
      setFieldValue("OtherBrand", "");
      setFieldValue("OtherModel", "");
      setFieldValue("carNickName", "");
      setFieldValue("Brand", e.label);

      await getCarModels(e.value);
    }

    if (isOtherCar(e.label, e.localized?.en)) {
      if (e.localized) {
        const modelValue = {
          label: "OTHER",
          localized: {
            en: "OTHER",
            ar: "سيارة أخرى"
          },
          brand: e.localized
        };

        setNewCar({ brand: e.localized, model: modelValue.localized });
        handleModelChange(modelValue, setFieldValue);
      }
    }
  };

  const handleModelChange = async (e, setFieldValue) => {
    setFieldValue("OtherModel", "");
    setFieldValue("Year", "");
    setFieldValue("plateNumber", "");
    setFieldValue("plateChar", "");
    setFieldValue("Model", e.label);

    if (e.value) setNewCar({ ...newCar, model: e.localized });

    const selectedBrand = e.brand?.[language] || newCar.brand?.[language];
    await getCarYears(selectedBrand, e.label);
  };

  const handleYearChange = async (e, setFieldValue) => setFieldValue("Year", e.value);

  // const closeEstimationExistModal = () => setEstimationExistModalOpen(false);

  const handleSubmit = async (values) => {
    // const estimation = await estimationRequest.validateCar(values.plateNumber + values.plateChar);
    // if (!isUpdating && estimation) return setEstimationExistModalOpen(true);

    setGlobalValues({ ...globalValues, ...values, isSavedCar });
    setView(2);
  };

  const vehicleDetailsValidations = () => {
    return Yup.object().shape({
      Brand: Yup.string().required("estimationRequest.required"),
      OtherBrand: Yup.string().when("Brand", {
        is: (val) => isOtherCar(val),
        then: (schema) => schema.required("estimationRequest.required")
      }),
      Model: Yup.string().required("estimationRequest.required"),
      OtherModel: Yup.string().when(["Brand", "Model"], {
        is: (Brand, Model) => isOtherCar(Brand, Model),
        then: (schema) => schema.required("estimationRequest.required")
      }),
      Year: Yup.string().required("estimationRequest.required"),
      plateNumber: Yup.string()
        .required("estimationRequest.required")
        .max(4, "estimationRequest.mustBeMax5Digits")
        .min(4, "estimationRequest.mustBeMin4Digits"),
      plateChar: Yup.string()
        .required("estimationRequest.required")
        .min(5, "estimationRequest.mustBeMin3Letters")
        .max(6, "estimationRequest.mustBeMax4Letters")
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          Brand: userCars?.[userCarIndex]?.brand?.["value_" + language] || Brand,
          OtherBrand: userCars?.[userCarIndex]?.otherBrand || OtherBrand || "",
          Model: userCars?.[userCarIndex]?.product?.["value_" + language] || Model,
          OtherModel: userCars?.[userCarIndex]?.otherModel || OtherModel || "",
          Year: userCars?.[userCarIndex]?.modelYear || Year,
          plateNumber: carNumberPlate[0] || plateNumber,
          plateChar: carNumberPlate[1] || plateChar,
          carNickName: userCars?.[userCarIndex]?.carNickName || carNickName
        }}
        enableReinitialize={!newCar?.brand}
        validationSchema={vehicleDetailsValidations}
        onSubmit={handleSubmit}>
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="car-details">
            <div className="container">
              <h4 className="text-sm-center mt-5 mb-4">
                {t("estimationRequest.vehicleDetails")}
                <span className="required-flag">*</span>
              </h4>

              <div className="col-md-10 col-lg-8 col-xl-6 divider car-insight">
                <div className="car-select">
                  <Select
                    name="Brand"
                    classNamePrefix="select"
                    options={carBrands}
                    onKeyDown={(e) => forKey(e, carBrands)}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(e) => handleBrandChange(e, setFieldValue)}
                    placeholder={t("estimationRequest.selectBrand")}
                    noOptionsMessage={() => t("estimationRequest.noOptions")}
                    isDisabled={isUpdating}
                    value={
                      values.Brand
                        ? {
                            value: values.Brand,
                            label: newCar?.brand?.[language] || values.Brand
                          }
                        : ""
                    }
                    ariaLiveMessages={{
                      onFocus: (e) => forFocus(e)
                    }}
                    menuPortalTarget={document.body}
                  />
                  {errors.Brand && touched.Brand && (
                    <span className="red-color">{t(errors.Brand)}</span>
                  )}

                  {isOtherCar(values.Brand) && (
                    <>
                      <Field
                        type="text"
                        id="OtherBrand"
                        name="OtherBrand"
                        className="form-control mt-2"
                        placeholder={t("estimationRequest.otherBrand")}
                        disabled={isUpdating}
                      />
                      {errors.OtherBrand && touched.OtherBrand && (
                        <span className="red-color">{t(errors.OtherBrand)}</span>
                      )}
                    </>
                  )}
                </div>

                <div className="car-select">
                  <Select
                    name="Model"
                    classNamePrefix="select"
                    options={modelOptions.map((car) => getLocalizedOptions(car))}
                    onKeyDown={(e) => forKey(e, modelOptions)}
                    onChange={(e) => handleModelChange(e, setFieldValue)}
                    placeholder={t("estimationRequest.SelectModel")}
                    noOptionsMessage={() => t("estimationRequest.noOptions")}
                    formatOptionLabel={formatOptionLabel}
                    isDisabled={isUpdating}
                    value={
                      values.Model
                        ? {
                            value: values.Model,
                            label: newCar?.model?.[language] || values.Model
                          }
                        : ""
                    }
                    ariaLiveMessages={{
                      onFocus: (e) => forFocus(e)
                    }}
                    menuPortalTarget={document.body}
                  />
                  {errors.Model && touched.Model && (
                    <span className="red-color">{t(errors.Model)}</span>
                  )}

                  {isOtherCar(values.Brand, values.Model) && (
                    <>
                      <Field
                        type="text"
                        id="OtherModel"
                        name="OtherModel"
                        className="form-control mt-2"
                        placeholder={t("estimationRequest.otherModel")}
                        disabled={isUpdating}
                      />
                      {errors.OtherModel && touched.OtherModel && (
                        <span className="red-color">{t(errors.OtherModel)}</span>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="col-md-10 col-lg-8 col-xl-6 divider car-insight">
                <div className="car-select">
                  <Select
                    classNamePrefix="select"
                    name="Year"
                    options={yearsOptions.map((year) => {
                      return {
                        label: isBefore2017(year),
                        value: year
                      };
                    })}
                    formatOptionLabel={formatOptionLabel}
                    ariaLiveMessages={{
                      onFocus: (e) => forFocus(e)
                    }}
                    onKeyDown={(e) => forKey(e, yearsOptions)}
                    onChange={(e) => handleYearChange(e, setFieldValue)}
                    placeholder={t("estimationRequest.SelectYear")}
                    noOptionsMessage={() => t("estimationRequest.noOptions")}
                    isDisabled={isUpdating}
                    value={
                      values.Year
                        ? {
                            label: isBefore2017(values.Year),
                            value: values.Year
                          }
                        : ""
                    }
                    menuPortalTarget={document.body}
                  />
                  {errors.Year && touched.Year && (
                    <span className="red-color">{t(errors.Year)}</span>
                  )}
                </div>

                <div className="divider mb-0">
                  <div className="mx-0">
                    <Field
                      type="text"
                      id="plateNumber"
                      name="plateNumber"
                      className="form-control"
                      placeholder={t("estimationRequest.licensePlate")}
                      inputMode="numeric"
                      onInput={handleCarPlateNum}
                      disabled={isUpdating}
                    />
                    {errors.plateNumber && touched.plateNumber && (
                      <span className="red-color">{t(errors.plateNumber)}</span>
                    )}
                  </div>

                  <div className="mx-0">
                    <Field
                      type="text"
                      id="plateChar"
                      name="plateChar"
                      placeholder={t("estimationRequest.egSSS")}
                      className="form-control"
                      disabled={isUpdating}
                      onInput={handleCarPlateChar}
                    />
                    {errors.plateChar && touched.plateChar && (
                      <span className="red-color">{t(errors.plateChar)}</span>
                    )}
                  </div>
                </div>
              </div>

              {(!isSavedCar || isUpdating) && (
                <div className="col-md-10 col-lg-8 col-xl-6 divider car-insight">
                  <Field
                    type="text"
                    id="carNickName"
                    name="carNickName"
                    className="form-control "
                    placeholder={t("scheduleAppointment.carNickName")}
                    disabled={isUpdating}
                  />
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button type="button" className="back-btn" onClick={() => setView(0)}>
                {t("estimationRequest.back")}
              </button>
              <input type="submit" className="mx-0" value={t("estimationRequest.nextTwo")} />
            </div>
          </Form>
        )}
      </Formik>

      {/* <EstimationExistModal show={estimationExistModalOpen} close={closeEstimationExistModal} /> */}
    </>
  );
};

export default React.memo(VehicleDetailsStep);
