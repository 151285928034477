import { createSlice } from "@reduxjs/toolkit";

const componentSlice = createSlice({
  name: "component",
  initialState: {
    loginModalState: false
  },
  reducers: {
    toggleLoginModalVisibility: (state, action) => {
      state.loginModalState = action.payload;
    }
  }
});

export const { toggleLoginModalVisibility } = componentSlice.actions;
export default componentSlice.reducer;
