import React, { useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Icon } from "./";
import { currentLanguage, isLtr, userLogout } from "../../util/common";
import { handleLayout, switchLanguage } from "../../util/layoutUtils";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserService } from "../../services";
import { useAppNavigation } from "../../hooks";
import { routesToHideHeaderAndFooter } from "../../util/constants";
import { toggleLoginModalVisibility } from "../../redux/reducers/componentReducer";

const Topbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const lang = currentLanguage();
  const { navigate, localeRoutes, toBringIt } = useAppNavigation();

  const userData = useSelector((state) => state.user.details);

  useEffect(() => {
    // Handle localization
    const pathname = location.pathname;
    const pathParts = pathname.split("/");
    const language = pathParts[1] === "en" ? "en" : "ar";
    i18n.changeLanguage(language);
    handleLayout();
  }, [location.pathname]);

  const openLoginModel = () => dispatch(toggleLoginModalVisibility(true));

  const logOut = async () => {
    if (location?.pathname === localeRoutes.appointment[lang]) toBringIt();
    await UserService.logout();
    userLogout(dispatch);
  };

  const openDialPad = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <>
      <section id="topbar" className="d-flex align-items-center sticky-top top-bar">
        {!routesToHideHeaderAndFooter.includes(location.pathname) && (
          <>
            <a
              href="https://wa.me/966920024420"
              className="whatsapp-icon"
              target="_blank"
              rel="noreferrer">
              <Icon iconName="whatsapp" />
            </a>
            <a className="social-link" onClick={() => openDialPad("920024420")}>
              <Icon iconName="phone" />
              <span>{t("topbar.phoneNumber")}</span>
            </a>
            {userData.id ? (
              <>
                <Link to={localeRoutes.myAccount[lang]} className="my-account-btn">
                  {t("topbar.myAccount")}
                </Link>
                <div className="logout-btn" title="Log out" onClick={logOut}>
                  <Icon iconName="signOut" />
                </div>
              </>
            ) : (
              <div className="signin-login">
                <span className="login-btn" onClick={openLoginModel}>
                  {t("topbar.loginText")}
                </span>
              </div>
            )}
          </>
        )}

        <div className="lang-box">
          <button onClick={() => switchLanguage(isLtr() ? "ar" : "en", navigate, location)}>
            {isLtr() ? "عربي" : "English"}
          </button>
        </div>
      </section>
    </>
  );
};

export default Topbar;
