import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { UserService } from "../../services";
import { useTranslation } from "react-i18next";
import {
  automateOTPFields,
  concatOTPDigits,
  currentLanguage,
  limitizeValue,
  loadStorage,
  removeKSACountryCode,
  selectInputText
} from "../../util/common";
import { Loader, FormTogglers, NotificationHelper, Button, Icon } from "./";
import { useDispatch } from "react-redux";
import { saveUser } from "../../redux/reducers/userReducer";
import { Errors } from "../../util/errorMessage";
import { ksaCountryCode } from "../../util/constants";
import { authValidations, authOTPValidation } from "../../util/validations";
import { persistor } from "../../redux/store";
import { useAppNavigation } from "../../hooks";

const LoginModal = (props) => {
  const { show, onCancel } = props;
  const lang = currentLanguage();

  const { t } = useTranslation();
  const { localeRoutes, toBringIt } = useAppNavigation();
  const location = useLocation();
  const dispatch = useDispatch();

  const phoneNumberRef = useRef();
  const otpFirstDigit = useRef();
  const otpSecondDigit = useRef();
  const otpThirdDigit = useRef();
  const otpFourthDigit = useRef();

  const [isLoading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentTab, setTab] = useState(1);
  const [lastOtp, setLastOtp] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");

  useEffect(() => {
    phoneNumberRef.current?.focus();
    otpFirstDigit.current?.focus();
  }, [show, currentTab]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const phoneNumber = ksaCountryCode + values.phoneNumber;
    setMobilePhone(phoneNumber);
    try {
      const response = await UserService.registerUser({
        phoneNumber
      });
      if (response) tabHandler(2);
    } catch (e) {
      console.error(e);
      const key = e?.response?.data?.key || "UNEXPECTED_ERROR";
      if (Errors[e?.response?.data?.key] !== undefined) {
        NotificationHelper.error(t(Errors[key]));
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyUserSubmit = async (values) => {
    const code = concatOTPDigits(values);

    if (code === lastOtp) {
      setShowError(true);
      return;
    }

    setLoading(true);
    setLastOtp(code);
    try {
      const verifyResponse = await UserService.verifyUser(code, mobilePhone);
      if (verifyResponse && verifyResponse.message === "user.verified") {
        dispatch(saveUser(verifyResponse?.user));
        onCancel();
        await persistor.flush();
        if (location?.pathname === localeRoutes.appointment[lang]) toBringIt();
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
      const key = e?.response?.data?.key || "UNEXPECTED_ERROR";
      if (Errors[e?.response?.data?.key] !== undefined) {
        NotificationHelper.error(t(Errors[key]));
        setLastOtp("");
        return;
      }
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const tabHandler = (tab) => {
    setShowError(false);
    setLastOtp("");
    setTab(tab);
  };

  return (
    <Modal show={show} centered={true}>
      <Loader show={isLoading} />

      <Modal.Body>
        <button onClick={onCancel} type="button" className="close login-modal-close-btn">
          <Icon iconName="xmark" />
        </button>

        <div className="login-form-main">
          <h4 className="text-center">{t("loginModal.loginText")}</h4>

          {currentTab == 1 && (
            <Formik
              initialValues={{
                phoneCode: ksaCountryCode,
                phoneNumber: removeKSACountryCode(mobilePhone) || ""
              }}
              validationSchema={authValidations}
              onSubmit={handleSubmit}>
              {({ values, errors, touched, dirty }) => (
                <Form>
                  <h6 className="text-center">{t("loginModal.enterYourPhoneNumber")}</h6>
                  <p className="text-center">{t("loginModal.loginDesc")}</p>
                  {/* <div>
                    <Field
                      type="number"
                      name="phoneNumber"
                      placeholder={t("loginModal.e.g")}
                      component={PhoneInputField}
                      country="sa"
                      onEnterPress={() => handleSubmit(values)}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <span
                        className="red-color text-start mt-2 d-block"
                        style={{ marginBottom: "-10px" }}>
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div> */}
                  <div>
                    <div className="phone-no-wrap mx-0">
                      <div>
                        <Field
                          type="number"
                          id="phoneCode"
                          name="phoneCode"
                          placeholder={ksaCountryCode}
                          className="form-control phone-code"
                          disabled
                        />
                      </div>
                      <div className="w-100">
                        <Field
                          type="number"
                          id="phoneNumber"
                          name="phoneNumber"
                          pattern="[0-9]*"
                          className="form-control"
                          innerRef={phoneNumberRef}
                          placeholder={t("loginModal.e.g")}
                          onInput={limitizeValue}
                        />
                        {errors.phoneNumber && touched.phoneNumber && (
                          <span className="red-color text-start mt-2 d-block">
                            {t(errors.phoneNumber)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }} className="mt-4">
                    <FormTogglers
                      dependentValue={values.phoneNumber}
                      dirty={dirty}
                      tabHandler={tabHandler}
                      customize={true}
                      t={t}
                    />
                  </div>

                  {loadStorage("session")?.expired && (
                    <p className="session-expired">{t("loginModal.sessionExpired")}</p>
                  )}
                </Form>
              )}
            </Formik>
          )}

          {currentTab == 2 && (
            <Formik
              enableReinitialize
              initialValues={{ code1: "", code2: "", code3: "", code4: "" }}
              validationSchema={authOTPValidation}
              onSubmit={handleVerifyUserSubmit}>
              {({ values, setFieldValue }) => {
                const handlePaste = (e) => {
                  // Prevent default paste behavior
                  e.preventDefault();

                  // Get clipboard data and remove any non-numeric characters
                  const paste = e.clipboardData.getData("text").replace(/\D/g, "");
                  const digits = paste.split(""); // Split the clipboard data into an array of digits

                  // If 4 digits are pasted, assign them to each input field
                  if (digits.length === 4) {
                    setFieldValue("code1", digits[0]);
                    setFieldValue("code2", digits[1]);
                    setFieldValue("code3", digits[2]);
                    setFieldValue("code4", digits[3]);

                    // Focus on the next input (if applicable)
                    otpSecondDigit.current.focus();
                  }
                  // Handle edge cases where fewer than 4 digits are pasted
                  else if (digits.length > 0) {
                    setFieldValue("code1", digits[0] || "");
                    setFieldValue("code2", digits[1] || "");
                    setFieldValue("code3", digits[2] || "");
                    setFieldValue("code4", digits[3] || "");

                    // Focus management depending on the number of pasted digits
                    if (digits.length === 1) otpSecondDigit.current.focus();
                    if (digits.length === 2) otpThirdDigit.current.focus();
                    if (digits.length === 3) otpFourthDigit.current.focus();
                  }
                };
                return (
                  <Form id="verifyUser">
                    <div className="opt-input-box">
                      <h6 className="text-center">{t("loginModal.optVerificationTitle")}</h6>
                      <p className="text-center">{t("loginModal.enterOtpSent")}</p>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row OTP">
                            <div className="col-sm-3">
                              <div className="box">
                                <Field
                                  type="number"
                                  id="code1"
                                  name="code1"
                                  placeholder="X"
                                  className="form-control inptFields one1"
                                  maxLength={1}
                                  innerRef={otpFirstDigit}
                                  pattern="[0-9]*"
                                  onInput={(e) =>
                                    automateOTPFields(e, otpSecondDigit, setShowError)
                                  }
                                  onFocus={selectInputText}
                                  onPaste={handlePaste}
                                />
                              </div>
                              <div className="col-sm-1"></div>
                            </div>
                            <div className="col-sm-3">
                              <div className="box">
                                <Field
                                  type="number"
                                  id="code2"
                                  name="code2"
                                  placeholder="X"
                                  className="form-control inptFields two2"
                                  maxLength={1}
                                  innerRef={otpSecondDigit}
                                  pattern="[0-9]*"
                                  onInput={(e) => automateOTPFields(e, otpThirdDigit, setShowError)}
                                  onKeyDown={(e) => automateOTPFields(e, otpFirstDigit)}
                                  onFocus={selectInputText}
                                />
                              </div>
                              <div className="col-sm-1"></div>
                            </div>
                            <div className="col-sm-3">
                              <div className="box">
                                <Field
                                  type="number"
                                  id="code3"
                                  name="code3"
                                  placeholder="X"
                                  className="form-control inptFields three3"
                                  maxLength={1}
                                  innerRef={otpThirdDigit}
                                  pattern="[0-9]*"
                                  onInput={(e) =>
                                    automateOTPFields(e, otpFourthDigit, setShowError)
                                  }
                                  onKeyDown={(e) => automateOTPFields(e, otpSecondDigit)}
                                  onFocus={selectInputText}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="box">
                                <Field
                                  type="number"
                                  id="code4"
                                  name="code4"
                                  placeholder="X"
                                  maxLength={1}
                                  innerRef={otpFourthDigit}
                                  className="form-control inptFields four4"
                                  pattern="[0-9]*"
                                  onInput={(e) => automateOTPFields(e, null, setShowError)}
                                  onKeyDown={(e) => automateOTPFields(e, otpThirdDigit)}
                                  onFocus={selectInputText}
                                />
                              </div>
                            </div>

                            {showError && (
                              <span className="red-color mt-3" style={{ marginBottom: "-5px" }}>
                                {t("loginModal.InvalidOtp")}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div style={{ display: "flex", justifyContent: "center" }} className="mt-4">
                        <Button type="small-roundedLeft" action={() => tabHandler(1)}>
                          {t("loginModal.backBtnText")}
                        </Button>
                        <input
                          type="submit"
                          className="ah-btn btn primary-btn"
                          value={t("loginModal.verifyButton")}
                          disabled={
                            values.code1 === "" ||
                            values.code2 === "" ||
                            values.code3 === "" ||
                            values.code4 === ""
                          }
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(LoginModal);
