import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cartReducer from "./reducers/cartReducer";
import userReducer from "./reducers/userReducer";
import contentReducer from "./reducers/contentReducer";
import componentReducer from "./reducers/componentReducer";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "your-secret-key",
      whitelist: ["cart", "user"],
      blacklist: ["content", "component"]
    })
  ]
  // whitelist: ["cart", "user"] // Optionally specify which reducers to persist
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    cart: cartReducer,
    user: userReducer,
    content: contentReducer,
    component: componentReducer
  })
);

const store = configureStore({
  reducer: persistedReducer
  // Optionally specify other configuration options here
});

// export default store;
const persistor = persistStore(store);

export { store, persistor };
