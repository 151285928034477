import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { currentLanguage, getCitiesFromBranches } from "../../util/common";
import { useTranslation } from "react-i18next";
import { getCitiesOptions, getCityLabel, getProviderOptions } from "../../util/selectOptions";
import { ErrorFocus, BranchDetails } from "./";
import { UserService } from "../../services";

const CitiesAndBranches = ({
  parentClass = "",
  values,
  errors,
  touched,
  serviceId,
  cityChangeCondition,
  handleCityChange,
  branchOptions,
  handleBranchChange,
  disableFields = false,
  branchDetailsMainClass,
  branchDetailsParentClass,
  branchDetails,
  disableFieldsForCad = false,
  setBranchesForCAD,
  setLoader = () => true
}) => {
  const { t } = useTranslation();
  const [cityOptions, setCityOptions] = useState([]);
  const [availableBranches, setAvailableBranches] = useState([]);

  useEffect(() => {
    if (serviceId) getBranchesForService();
  }, [serviceId, values.cad]);

  const lang = currentLanguage();
  const cities = getCitiesOptions(cityOptions);
  const branches = getProviderOptions(branchOptions, t);

  const getBranchesForService = async () => {
    setLoader(true);
    try {
      const branches = await UserService.getProviders(serviceId);
      if (branches?.length > 0) {
        setAvailableBranches(branches);
        setBranchesForCAD && setBranchesForCAD(branches);
        const cities = getCitiesFromBranches(branches);
        setCityOptions(cities);

        if (cityChangeCondition && cities.length === 1)
          await handleCityChange({ value: cities[0][lang] }, serviceId, branches);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const toCheckBranchExistOrNot = useCallback((_branches) => {
    return _branches.some((obj) => obj.options.length > 0);
  }, []);

  return (
    <>
      <div className={parentClass}>
        <div>
          <Select
            id="City"
            name="City"
            className="selectCustom"
            classNamePrefix="step3"
            options={cities}
            onChange={(e) => handleCityChange(e, serviceId, availableBranches)}
            value={
              values.City
                ? {
                    value: values.City,
                    label: getCityLabel(cityOptions, values.City)
                  }
                : ""
            }
            noOptionsMessage={() => t("scheduleAppointment.noOptions")}
            placeholder={t("scheduleAppointment.selectCity")}
            isDisabled={disableFieldsForCad ? disableFieldsForCad : disableFields}
          />
          <div id="ErrorCity">
            {errors.City && touched.City && <span className="red-color">{t(errors.City)}</span>}
            <ErrorFocus />
          </div>
        </div>

        <div title={branchDetails?.name?.["value_" + lang]}>
          <Select
            id="Provider"
            name="Provider"
            className="selectCustom"
            classNamePrefix="step3"
            options={branches}
            onChange={(e) => handleBranchChange(e, undefined, serviceId)}
            value={
              values.Provider
                ? {
                    value: values.Provider,
                    label: branchDetails?.name?.["value_" + lang]
                  }
                : ""
            }
            noOptionsMessage={() => t("scheduleAppointment.noOptions")}
            placeholder={t("scheduleAppointment.selectServiceProvider")}
            isDisabled={disableFields}
          />

          <div id="ErrorProvider">
            {errors.Provider && touched.Provider && (
              <span className="red-color">{t(errors.Provider)}</span>
            )}
            <ErrorFocus />
          </div>
        </div>
      </div>

      {values?.cad &&
      values?.DeliveryAddress?.length > 0 &&
      values?.CollectionAddress?.length > 0 ? (
        <div className="no-branch-text-wrapper">
          {!toCheckBranchExistOrNot(branches) && (
            <span className="red-color ">{t("scheduleAppointment.noBranchForCAD")}</span>
          )}
        </div>
      ) : null}

      <BranchDetails
        details={branchDetails}
        mainClass={branchDetailsMainClass}
        parentClass={branchDetailsParentClass}
      />
    </>
  );
};

export default React.memo(CitiesAndBranches);
