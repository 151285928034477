import { useState, useEffect } from "react";
import AutohubService from "../services/autohubServices";

const useCatalog = () => {
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getCategories();
    getServices({ id: "", searchFilter: "" });
  }, []);

  const getCategories = async () => {
    try {
      const response = await AutohubService.getSevriceCategories();
      setCategories([
        { _id: "", name: { value_en: "All Services", value_ar: "جميع الخدمات" } },
        ...response
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const getServices = async ({ id = "", searchFilter = "" }) => {
    setLoading(true);
    try {
      const services = await AutohubService.getServices({
        filterKey: "isShowInService",
        categoryId: id,
        searchFilter,
        fromCatalogScreen: true
      });
      if (services.length) {
        // const categories = [];
        const filteredServices = { running: [], comingSoon: [] };
        services.forEach((cs = {}) => {
          // let isRunning = false;
          // categories.push(cs.category);
          // cs.services?.forEach((s) => {
          //   if (!s.isComingSoon) isRunning = true;
          //   else filteredServices.comingSoon.push(s);
          // });
          filteredServices.running.push(cs);
          // if (isRunning) {
          //   isRunning = false;
          // }
        });

        setServices(filteredServices);
      } else {
        setServices([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const categoryHandler = async (id) => {
    setSelectedCategory(id);
    setSearchTerm("");
    await getServices({ id });
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (selectedCategory) setSelectedCategory("");
    await getServices({ filterKey: "", searchFilter: searchTerm });
  };

  return {
    categories,
    services,
    selectedCategory,
    searchTerm,
    loading,
    setSearchTerm,
    handleSearchSubmit,
    categoryHandler,
    setLoading
  };
};

export default useCatalog;
