import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { tamaraLogoSet } from "../../util/constants";
import { currentLanguage } from "../../util/common";

const TamaraTag = ({ type = "info", parentClass = "", price }) => {
  const parentClassName = "product-detail-tag " + parentClass;
  // const isTamaraApplicable = price >= 1 && price <= 7000;
  const lang = currentLanguage();
  const _price = price;

  // const widgetConfig = {
  //   info: {
  //     className: "tamara-summary",
  //     initPayload: {
  //       currency: "SAR",
  //       country: "SA",
  //       lang,
  //       paymentType: ["installment", "paylater"],
  //       publicKey: "52da8ed0-6cd9-44a9-bdb6-c1bb133b0ca1"
  //     },
  //     scriptSrc: "https://cdn.tamara.co/widget-v2/tamara-widget.js"
  //   },
  //   installmentPlan: {
  //     className: "tamara-summary",
  //     initPayload: {
  //       currency: "SAR",
  //       country: "SA",
  //       lang: ["en"],
  //       price,
  //       numberOfInstallments: 4,
  //       publicKey: "52da8ed0-6cd9-44a9-bdb6-c1bb133b0ca1"
  //     },
  //     scriptSrc: "https://cdn.tamara.co/widget-v2/tamara-widget.js"
  //   }
  // };
  // useEffect(() => {
  //   const reloadPage = () => window.location.reload();

  //   return () => {
  //     reloadPage();
  //   };
  // }, [lang]);

  const { t } = useTranslation();

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (type === "info" && window.TamaraWidget) {
  //       window.TamaraWidget.init(widgetConfig[type].initPayload);
  //       window.TamaraWidget.render();
  //     } else if (type === "installmentPlan" && isTamaraApplicable && window.TamaraInstallmentPlan) {
  //       window.TamaraInstallmentPlan.init(widgetConfig[type].initPayload);
  //       window.TamaraInstallmentPlan.render();
  //     }
  //   }, 500);
  // }, [price, lang]);

  window.tamaraWidgetConfig = {
    lang,
    country: "SA",
    currency: "SAR",
    publicKey: "52da8ed0-6cd9-44a9-bdb6-c1bb133b0ca1",
    price
  };

  return (
    <>
      <div className={parentClassName}>
        <div className="detail-tag">
          <div className="img-tag">
            <img width={60} src={tamaraLogoSet[lang]} />
          </div>
          <div className="text-tag">
            <span>{t("shop.tamaraTagText")}</span>
            {/* {(type === "info" || isTamaraApplicable) && (
              <div className={widgetConfig[type].className} />
            )} */}
            <tamara-widget
              type="tamara-summary"
              amount={_price ? _price : 0}
              inline-type="2"></tamara-widget>
          </div>
        </div>
      </div>

      <Helmet>
        <script defer src={"https://cdn-sandbox.tamara.co/widget-v2/tamara-widget.js"}></script>
      </Helmet>
    </>
  );
};

export default React.memo(TamaraTag);
