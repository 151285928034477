import moment from "moment";
import { getFormatedDate } from "./common";

const getDates = (startDate, stopDate) => {
  var start = new Date(startDate);
  var end = new Date(stopDate);
  let holidays_ = [];
  // Logic for getting rest of the dates between two dates("FromDate" to "EndDate")
  while (start <= end) {
    holidays_.push(getFormatedDate(moment(start), "YYYY-MM-DD"));
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  return holidays_;
};

export const getHolidayDates = (ocassions) => {
  let holidayDates = [];
  if (ocassions.length) {
    ocassions.forEach((o) => {
      if (o.isHoliday) {
        const datesArr = getDates(o.dateRange.from, o.dateRange.to);
        holidayDates = holidayDates.concat(datesArr);
      }
    });
  }

  return holidayDates;
};
