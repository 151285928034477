import axios from "axios";
import {
  API_CREATE_SCHEDULE_APPOINTMENT,
  API_CREATE_RESCHEDULE_APPOINTMENT,
  API_CANCEL_APPOINTMENT,
  API_GET_CAR_OWN_BY,
  API_GET_BOOKING_APPOINTMENT_FOR,
  API_GET_REQUIRED_DOCUMENTS,
  API_GET_APPOINTMENTS,
  API_GET_APPOINTMENT_DAYS,
  API_UPDATE_REQUIRED_DOCUMENTS,
  API_TRACK_PROGRESS,
  API_CND_APPOINTMENT_PROGRESS,
  API_VERIFY_BRANCH_CODE,
  API_CREATE_COLLECTION_APPOINTMENT,
  API_GET_APPOINTMENT_BY_APPOINTMENTHASH
} from "../config/webService";
import BaseService from "../config/baseService";
import moment from "moment";
class AppointmentService extends BaseService {
  async getAppointmentDays({ branchId, serviceId, day }) {
    let currentDay = new Date();
    currentDay = currentDay.toISOString();
    const currentTime = moment(currentDay).format("hh:mma");
    const monthFirstDay = day ? moment(day).startOf("day") : null 
    const result = await axios.get(API_GET_APPOINTMENT_DAYS, {
      params: {
        branchId,
        serviceId,
        date: monthFirstDay || currentDay,
        currentDay,
        currentTime
      }
    });
    return result?.data?.response;
  }

  // async validateAppointmentCar(carNumberPlate) {
  //   try {
  //     const response = await axios.get(
  //       `${API_VALIDATE_APPOINTMENT_CAR}?carNumberPlate=${carNumberPlate}`
  //     );
  //     return response && response.data;
  //   } catch (er) {
  //     console.error(er);
  //   }
  // }

  async scheduleAppointment({
    userId,
    email,
    title,
    firstName,
    lastName,
    phoneNumber,
    brand,
    model,
    otherBrand,
    otherModel,
    carNumberPlate,
    carNickName,
    year,
    city,
    branchId,
    services,
    images,
    imageNote,
    date,
    language,
    carOwnBy,
    bookingAppointmentFor,
    acknowledged,
    estimationId,
    isPaidService,
    isAddonSelected,
    quotationId,
    userQuotation,
    userSubscription,
    cad = false,
    CollectionAddress,
    DeliveryAddress,
    sameAsCollectionAddress,
    needCareemPromo
  }) {
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("source", "autohub");
    formData.append("email", email);
    formData.append("title", title);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("brand", brand);
    formData.append("model", model);
    formData.append("otherBrand", otherBrand);
    formData.append("otherModel", otherModel);
    formData.append("carNumberPlate", carNumberPlate);
    formData.append("carNickName", carNickName);
    formData.append("year", year);
    formData.append("city", city);
    formData.append("branchId", branchId);
    formData.append("carOwnBy", carOwnBy);
    formData.append("bookingAppointmentFor", bookingAppointmentFor);
    formData.append("acknowledged", acknowledged);
    formData.append("needCareemPromo", needCareemPromo);
    estimationId && formData.append("estimationId", estimationId);
    formData.append("isPaidService", isPaidService);
    formData.append("isAddonSelected", isAddonSelected);
    quotationId && formData.append("quotationId", quotationId);
    userQuotation && formData.append("userQuotation", userQuotation);
    userSubscription && formData.append("subscribedSubscriptionId", userSubscription);

    services.forEach((service) => formData.append("services[]", JSON.stringify(service)));
    images && images.forEach((image) => formData.append("images[]", image));
    imageNote && formData.append("imageNote", imageNote);
    formData.append("date", date);
    formData.append("language", language);

    if (cad) {
      formData.append("CollectionAddress", CollectionAddress);
      formData.append("DeliveryAddress", DeliveryAddress);
      formData.append("sameAsCollectionAddress", sameAsCollectionAddress);
      formData.append("cad", cad);
    }

    const result = await axios.post(API_CREATE_SCHEDULE_APPOINTMENT, formData);
    return result?.data?.response;
  }

  async rescheduleAppointment({ userId, appointmentId, date, services, language, phoneNumber }) {
    const result = await axios.put(API_CREATE_RESCHEDULE_APPOINTMENT, {
      date: date,
      userId: userId,
      services,
      appointmentId: appointmentId,
      language: language,
      phoneNumber
    });
    return result?.data?.response;
  }

  async getCarOwnBy(id) {
    const result = await axios.get(API_GET_CAR_OWN_BY, {
      params: {
        id
      }
    });
    return result?.data?.response;
  }

  async getBookingAppointmentFor(id, serviceID) {
    const result = await axios.get(API_GET_BOOKING_APPOINTMENT_FOR, {
      params: {
        id,
        serviceID
      }
    });
    return result && result.data?.response;
  }

  async getRequiredDocuments(carOwnBy, bookingAppointmentFor) {
    const result = await axios.get(API_GET_REQUIRED_DOCUMENTS, {
      params: {
        carOwnBy,
        bookingAppointmentFor
      }
    });
    return result && result.data?.response;
  }

  async cancelAppointment(appointmentId, reason, estimationId) {
    const result = await axios.put(API_CANCEL_APPOINTMENT, {
      appointmentId: appointmentId,
      reason: reason,
      estimation: estimationId
    });
    return result?.data?.response;
  }

  async getAppointments(filterKey, doc) {
    try {
      let key =
        filterKey[0] === "?"
          ? `searchFilter=${filterKey.slice(1)}`
          : filterKey
            ? `appointmentId=${filterKey}`
            : `isCanceled=${filterKey}`;
      const result = await axios.get(`${API_GET_APPOINTMENTS}?${key}&doc=${doc}`);
      return result?.data?.response;
    } catch (error) {
      console.error(error);
    }
  }

  async updateRequiredDocument(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.put(API_UPDATE_REQUIRED_DOCUMENTS, formData);
    return result?.data?.response;
  }

  async getProgress() {
    const result = await axios.get(API_TRACK_PROGRESS);
    return result?.data?.response;
  }

  async getCNDAppointment(phoneNumber) {
    const result = await axios.get(API_CND_APPOINTMENT_PROGRESS, { params: { phoneNumber } });
    return result?.data?.response;
  }

  async verifyDropCode({ branch, dropCode }) {
    const result = await axios.get(API_VERIFY_BRANCH_CODE, {
      params: { slug: branch, branchCode: dropCode }
    });
    return result?.data;
  }

  async createCollectionAppointment(data) {
    const result = await axios.post(API_CREATE_COLLECTION_APPOINTMENT, data);
    return result?.data;
  }

  async getAppointmentByHash(appointmentHash) {
    const result = await axios.get(API_GET_APPOINTMENT_BY_APPOINTMENTHASH, {
      params: { appointmentHash }
    });
    return result?.data;
  }
}
export default new AppointmentService();
