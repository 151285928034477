import React from "react";
import Icon from "./Icons";
import Loader from "./loader";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { Accordion, Modal } from "react-bootstrap";
import { GET_MEDIA_URL } from "../../config/webService";
import { currentLanguage, getFormatedDate } from "../../util/common";

const CampaignViewModal = ({ show, onCancel, offer, loading, setLoading, goToBooking }) => {
  const { t } = useTranslation();
  const lang = currentLanguage();

  const handleImageLoad = () => setLoading(false);

  return (
    <Modal size="lg" show={show} onHide={onCancel} centered>
      <Loader show={loading} />

      <Modal.Body className="p-4">
        <button onClick={onCancel} type="button" className="close login-modal-close-btn">
          <Icon iconName="xmark" />
        </button>

        <div className="container-fluid detail-show campaign-container">
          <div className="recent-details-img">
            <img src={GET_MEDIA_URL + offer?.["campaignImage_" + lang]} onLoad={handleImageLoad} />
          </div>
          <div className="button-and-expiry-wrap">
            <div className="book-btn-wrapper mt-4">
              <Button type="big-roundedRight" action={goToBooking}>
                {t("offersDetail.bookAppointment")}
              </Button>
            </div>

            <div className="campaign-metadata mt-4">
              <div>
                <div>
                  <span>{t("offersDetail.oldPrice")}</span>
                  <del className="fw-bold">
                    {offer?.price} {t("addons.currency")}
                  </del>
                </div>
                <div>
                  <span>{t("offersDetail.price")}</span>
                  <span className="fw-bold">
                    {offer?.campaignPrice} {t("addons.currency")}
                  </span>
                </div>
              </div>

              <div>
                <span>{t("offersDetail.campaignExpiryDate")}:</span>
                <span className="fw-bold">
                  {getFormatedDate(offer?.campaignEndDate, "YYYY-MM-DD") || ""}
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="offer-details-sec">
                <h1 className="margin-0">{offer?.name?.["value_" + lang] || ""}</h1>
                <br />
                <Accordion defaultActiveKey={1}>
                  <Accordion.Item eventKey={1}>
                    <Accordion.Header>
                      <h2>{t("offersDetail.title")}</h2>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p
                        className="shortDesc"
                        dangerouslySetInnerHTML={{
                          __html: offer?.campaignTermsAndConditions?.["value_" + lang] || ""
                        }}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CampaignViewModal;
